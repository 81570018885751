















import {Component, Vue} from 'vue-property-decorator';
import Popular from '@/components/search/Popular.vue';

@Component({
  components: {Popular},
})
export default class Search extends Vue {}
